import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Misc/Share'

// Hooks
import usePage from 'hooks/usePage'

// Images
import Location from 'img/location-transparent.inline.svg'
import Clock from 'img/clock-transparent.inline.svg'
import Bars from 'img/bars-transparent.inline.svg'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section`
  z-index: 4;
`

const Vacancy = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);

  @media (min-width: 992px) {
    padding: 3rem 5rem 18rem 5rem;
  }

  @media (max-width: 991.98px) {
    padding: 1.5rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.family.secondary};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.ultra};
  }
`

const Details = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
`

const Content = styled(ParseContent)`
  & h1,
  & h2 {
    font-size: ${({ theme }) => theme.font.size.bigger};
    line-height: ${({ theme }) => theme.font.size.bigger};
    margin-bottom: 0.25rem;
  }

  & h3 {
    font-size: 18px;
    line-height: ${({ theme }) => theme.font.size.bigger};
    padding-left: 2rem;
    margin-bottom: 0;
    margin-top: 1rem;
  }
`

const TextVacancy: React.FC<TextProps> = ({ fields }) => {
  const { title, location, hours, level, uri }: any = usePage()

  return (
    <Section className="position-relative">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className={`col-lg-${fields.containerwidth}`}>
            <Vacancy>
              <Share title={title} excerpt="" src={uri} />
              <Title className="my-5">{title}</Title>
              <Details className="row py-3 mb-5 gx-0">
                <div className="col-lg-8">
                  <div className="d-flex flex-column flex-sm-row justify-content-between">
                    <div className="d-flex align-items-center mb-2">
                      <Location />
                      <span className="ms-3">{location}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <Clock />
                      <span className="ms-3">{hours}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <Bars />
                      <span className="ms-3">{level}</span>
                    </div>
                  </div>
                </div>
              </Details>
              <Content content={fields.description} />
            </Vacancy>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TextVacancy
